/* eslint-disable array-callback-return */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Chart, Bubble, Pie, Bar, Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useLocation, useHistory } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Input from '@mui/material/Input';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Switch from '@mui/material/Switch';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import format from 'date-fns/format';
import GetApp from '@mui/icons-material/GetApp';
import Search from '@mui/icons-material/Search';
import GridOn from '@mui/icons-material/GridOn';
import html2PDF from 'jspdf-html2canvas';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Label from '@mui/icons-material/Label';
import LabelOffTwoTone from '@mui/icons-material/LabelOffTwoTone';
import Description from '@mui/icons-material/Description';
import DescriptionTwoTone from '@mui/icons-material/DescriptionTwoTone';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import useApi from '../functions/useApi';
import UserAnalyticsTable from '../components/UserAnalyticsTable';
import { MonthNames } from '../constants/Months';
import Tags from '../components/Tags';
import UserForm from '../components/UserForm';
import { groupWellness } from '../functions/wellness';
import { useAuthContext } from '../context/AuthContext';
import DeleteDialog from '../components/DeleteDialog';
import { UsersContext, useUsersContext } from '../context/UsersContext';
import { CompanyContext } from '../context/CompanyContext';
import { useAlertContext } from '../context/AlertContext';
import { useAssessmentContext } from '../context/AssessmentContext';
import { TagsContext } from '../context/TagsContext';
import { csvDownload } from '../functions/csvDownload';
import QuestionCharts from '../components/QuestionCharts';

const style = makeStyles(({ spacing, palette }) => ({
  card: {
    marginRight: 20,
    marginBottom: 10,
    marginTop: 10,
    borderRadius: spacing(0.5),
    width: '100%',
    '& .MuiCardHeader-root': {
      backgroundColor: palette.secondary.main,
      paddingTop: '0.4rem',
      paddingBottom: '0.3rem',
      color: palette.secondary.contrastText,
      textAlign: 'center',
      flexGrow: 1,
    },
  },
  CardHeader: {
    backgroundColor: palette.secondary.main,
    paddingTop: '0.4rem',
    paddingBottom: '0.3rem',
    color: palette.secondary.contrastText,
  },
  TagCardHeader: {
    padding: 0,
    backgroundColor: palette.secondary.main,
    paddingTop: '0.4rem',
    paddingBottom: '0.3rem',
    color: palette.secondary.contrastText,
  },
  CardContent: { justifyContent: 'center', display: 'flex' },
  fab: {
    position: 'absolute',
    marginTop: 40,
    right: 40,
  },
  root: {
    height: '100%',
  },
  paginator: {
    float: 'right',
  },
  grid: {
    display: 'flex',
  },
  switch: {
    float: 'right',
    marginLeft: 'auto',
    '& .MuiSwitch-thumb': {
      // marginBottom: '2rem',
      // marginTop: '2rem',
      // paddingTop: '2rem',
      // paddingBottom: '2rem',
    },
  },
  select: {
    float: 'right',
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: 240,
    borderRadius: spacing(0.5),
    '& .MuiInputBase-input': {
      marginLeft: '1rem',
    },
  },
  formControl: {
    marginTop: '1rem',
    marginBottom: '1rem',
    width: '90%',
    marginLeft: '5%',
  },
  pie: {
    maxWidth: '16rem',
    maxHeight: '16rem',
  },
  buttonGroup: {
    marginBottom: '1rem',
  },
  toolbar: {
    backgroundColor: palette.primary.main,
    borderRadius: '0.2rem',
    color: palette.primary.contrastText,
  },
  cardTitle: {
    textAlign: 'center',
  },
  questionCardHeader: {
    textAlign: 'center',
    height: '2.5rem',
  },
  formLabel: {
    marginLeft: '1rem',
    paddingBottom: '1rem',
    color: palette.secondary.contrastText,
  },
  input: { color: palette.primary.contrastText },
  iconButton: { color: palette.primary.contrastText },
  button: {
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
    '&:hover': {
      backgroundColor: palette.secondary.main,
      color: palette.secondary.contrastText,
    },
  },
  icon: {
    color: palette.primary.contrastText,
    marginBottom: '1rem',
    paddingBottom: '1rem',
    paddingTop: '-6rem',
    // marginTop: '0.1rem',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: spacing(3),
  },
  popOverGridContainer: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '1rem',
    paddingTop: '1rem',
  },

  filterChip: {
    marginTop: '0.25rem',
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
    minHeight: '1rem',
    textColor: palette.secondary.contrastText,

    '& .MuiButtonBase-root': {
      backgroundColor: palette.secondary.main,
      borderRadius: '0.2rem',
    },

    '& .MuiCardHeader-title': { color: palette.secondary.contrastText },
    '& .MuiIconButton-root': { color: palette.secondary.contrastText },
    '& .MuiTypography-root': { color: palette.secondary.contrastText },
  },
}));

const titleTypographyProps = { titleTypographyProps: { variant: 'h6' } };

export default function Analytics(props) {
  // Adds support for labels for charts
  Chart.register(ChartDataLabels);
  // Sets display labels to false
  Chart.defaults.set('plugins.datalabels', {
    display: false,
  });

  const classes = style();
  const { put, post } = useApi();
  const { users } = useContext(UsersContext);
  const { terms, dispatchTerms, defaultTagFilters, details, identifiedData } =
    useContext(CompanyContext);
  const {
    form,
    assessmentFilter,
    previousAssessmentDatesObject,
    previousAssessmentDates,
  } = useAssessmentContext();
  const [filterObject, setFilterObject] = useState({});
  const { windowSmall } = props;
  const authContext = useAuthContext();
  const [query, setQuery] = useState('');
  // Simple Pagination for Table!?
  const [tableFilter, setTableFilter] = useState(0);
  const [checked, setChecked] = useState(false);
  const [tagsDraw, setTagsDraw] = useState(false);
  const [historicalUserData, setHistoricalUserData] = useState([]);
  const [formAnswers, setFormAnswers] = useState({});
  const [companyScoreData, setCompanyScoreData] = useState([]);
  const [lastSixIterations, setLastSixIterations] = useState([]);
  const [userDraw, setUserDraw] = useState(false);
  const [usersAmount, setUsersAmount] = useState(0);
  const [maxAmountAverageResUsers, setMaxAmountAverageResUsers] = useState(0);
  const alertContext = useAlertContext();
  const { tags, reload } = useContext(TagsContext);
  const isMounted = useRef(false);
  const toolbarRef = useRef();
  const [toolbarWidth, setToolbarWidth] = useState(0);
  const { usersReload } = useUsersContext();
  const history = useHistory();
  const location = useLocation();

  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickTag = () => setAnchorEl(toolbarRef.current);

  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    setToolbarWidth(toolbarRef.current.clientWidth);
  }, [toolbarRef.current]);

  const open = Boolean(anchorEl);
  const tagPopoverId = open ? 'tag-popover' : undefined;

  const handleCloseDialog = async () => {
    alertContext.error(
      'error',
      'Can not continue without accepting terms and conditions'
    );
  };

  const changeTableFilter = () => {
    if (checked === false) {
      setTableFilter(1);
      setChecked(true);
    } else {
      setTableFilter(0);
      setChecked(false);
    }
  };

  const reloadUrlParam = new URLSearchParams(useLocation().search).get(
    'reload'
  );

  useEffect(() => {
    if (reloadUrlParam === '1') {
      window.scrollTo(0, 0);
      reload();
      usersReload();
    }
  }, []);

  useEffect(async () => {
    setUsersAmount(users.length);
    setMaxAmountAverageResUsers(
      Math.max(...groupWellness(users).map((i) => i.y))
    );
  }, [users]);

  const historicalData = async (v) => {
    if (v.length > 0) {
      let cursor = 0;
      let partsOfUsers = [];

      while (cursor || cursor === 0) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const res = await post({
            url: `assessment/history/${cursor}`,
            body: { iterations: v },
          });

          if (res.cursor > 0) {
            cursor = res.cursor;
          } else cursor = false;

          if (Array.isArray(res.message)) {
            partsOfUsers = [
              ...partsOfUsers,
              ...res.message.map((u) => ({
                ...u,
                ...(u.tags && { tags: JSON.parse(u.tags) }),
                ...(u.test && { test: JSON.parse(u.test) }),
              })),
            ];
          }
        } catch (error) {
          console.error(error);
        }
      }
      const questionData = {};

      const questionAnswers = [1, 2, 3, 4, 5];

      partsOfUsers.forEach((u) => {
        u.test.forEach((t) => {
          // question object exists so answer just needs to be incremented
          if (questionData[t.question_id]) {
            questionData[t.question_id][t.answer]++;
          } else {
            // question object does not exist so it needs to be created
            questionData[t.question_id] = {
              ...{
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                question: t.question,
                question_id: t.question_id,
              },
              ...{
                [t.answer]: 1,
              },
            };
          }
        });
      });

      Object.keys(questionData).forEach((t, b) => {
        // multiply answer amount by question weight
        const sumOfQuestionResult = questionAnswers.reduce(
          (res, cur) => res + questionData[t][cur] * cur,
          0
        );

        // total sum of times question was answered
        const numberofResults = questionAnswers.reduce(
          (res, cur) => res + (questionData[t][cur] || 0),
          0
        );

        questionData[t].averageAnswer = Math.round(
          sumOfQuestionResult / numberofResults
        );

        questionData[t].sumOfQuestionResult = sumOfQuestionResult;

        questionData[t].numberofResults = numberofResults;
      });

      setFormAnswers(questionData);

      return partsOfUsers;
    }
    return [];
  };

  const scoreTimelineData = async (v) => {
    // Make array of last 6 iterations [5,6,7,8,9,10]
    let requestedIterations = [
      ...Array(
        details.currentAssessmentIteration
          ? details.currentAssessmentIteration + 1 // add +1 as array generation starts at 0
          : 6
      ).keys(),
    ];

    if (requestedIterations.length > 8) {
      requestedIterations = requestedIterations.slice(
        requestedIterations.length - 6,
        requestedIterations.length
      );
    }

    const prevDates = await previousAssessmentDatesObject;
    setLastSixIterations(
      Object.values(previousAssessmentDatesObject).map((i) =>
        format(i, 'dd/MM/yyyy')
      )
    );
    try {
      const res = await historicalData(requestedIterations);

      // TODO refactor but it works
      // Condense results into one array per iteration
      const iterationData = {};
      res.map((e, i) => {
        iterationData[e.assessment_iteration] = [
          ...(iterationData[e.assessment_iteration] || []),
          ...[e],
        ];
      });

      // Combine all resilience scores for each iteration
      // Combine all safety scores for each iteration

      let combinedIterationData = Object.keys(iterationData).map((e, i) =>
        iterationData[e].reduce((a, b) => ({
          resilience: a.resilience + b.resilience,
          safety: a.safety + b.safety,
        }))
      );

      // Find average of each resilience and safety score round to 2 decimals
      combinedIterationData = combinedIterationData.map((s, i) => ({
        resilience:
          Math.round((s.resilience / iterationData[i + 1].length) * 100) / 100,
        safety:
          Math.round((s.safety / iterationData[i + 1].length) * 100) / 100,
      }));

      setCompanyScoreData(combinedIterationData);

      return combinedIterationData;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(async () => {
    if (isMounted.current) {
      setHistoricalUserData(await historicalData(assessmentFilter));
    } else {
      isMounted.current = true;
      scoreTimelineData();
    }
  }, [assessmentFilter]);

  const filteredSearch = users?.filter(
    (user) =>
      JSON.stringify(user).toLowerCase().indexOf(query.toLowerCase()) !== -1
  );

  const filteredUsers = filteredSearch?.filter((u) =>
    Object.values(filterObject).flat(1).length > 0
      ? (u.tags ? u.tags.map((y) => y.id) : []).some((t) =>
          Object.values(filterObject).flat(1).includes(t)
        )
      : true
  );

  const filteredSearchGraph = () => {
    if (historicalUserData.length > 0) {
      return historicalUserData.filter(
        (user) =>
          JSON.stringify(user).toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return [];
  };

  const filteredUsersGraphs = filteredSearchGraph()?.filter((u) =>
    Object.values(filterObject).flat(1).length > 0
      ? (u.tags ? u.tags.map((y) => y.id) : []).some((t) =>
          Object.values(filterObject).flat(1).includes(t)
        )
      : true
  );

  const whichUserData = () =>
    filteredUsersGraphs.length > 0 ? filteredUsersGraphs : filteredUsers;

  useEffect(async () => {
    setMaxAmountAverageResUsers(
      Math.max(...groupWellness(filteredUsers).map((i) => i.y))
    );
  }, [filteredUsers]);

  const [saveDefaultTags, setSaveDefaultTags] = useState(false);

  /**
   * @description - update urlparams to reflect filter changes
   * useEffect will then update filterObject and filteredUsers
   * @param {Object} tag - entire tag group object
   * @param {Array} value - array of tag ids
   * @param {string} name - id of tag group number as string
   */
  const updateFilter = (tag, value, name) => {
    if (value.length > 0) {
      history.push({
        pathname: history.location.pathname,
        search: `${history.location.search.slice(
          0,
          history.location.search.indexOf('&filter')
        )}&filters=${JSON.stringify({ ...filterObject, [name]: value })}`,
      });
    } else {
      history.push({
        pathname: history.location.pathname,
        search: `${history.location.search.slice(
          0,
          history.location.search.indexOf('&filter')
        )}&filters=${JSON.stringify({ ...filterObject, [name]: [] })}`,
      });
    }

    setSaveDefaultTags(true);
  };

  useEffect(() => {
    // Get all the tag ids from within the tag groups in one array
    const tagIds = tags
      .map((e) => e.options || [].map((o) => o.value))
      .flat(1)
      .map((tag) => tag.value);

    // Get all the tagGroupIds in one array
    const TagGroupIds = tags.map((t) => t.tag_group_id);

    const currentTags = {};

    TagGroupIds.forEach((id) => {
      if (
        // check if the object value has an array that's not empty

        Array.isArray(defaultTagFilters[id]) &&
        defaultTagFilters[id].length > 0
      ) {
        currentTags[id] = defaultTagFilters[id].filter(
          (t) => tagIds.includes(t)
          // filter out tags that are not in the tagIds array as they are old tags
        );
      }
    });

    if (
      Object.keys(
        JSON.parse(new URLSearchParams(location.search).get('filters'))
      ).length <= 0
    ) {
      history.push({
        pathname: history.location.pathname,
        search: `${history.location.search.slice(
          0,
          history.location.search.indexOf('&filter')
        )}&filters=${JSON.stringify(currentTags)}`,
      });
    }

    setSaveDefaultTags(false);
  }, [tags]);

  // Get filters from url search params
  useEffect(() => {
    const filters = new URLSearchParams(location.search).get('filters');
    const parsedFilters = filters ? JSON.parse(filters) : false;
    if (parsedFilters) {
      setFilterObject(parsedFilters);
    }
  }, [location]);

  const commitDefaultTags = async (defaults) => {
    try {
      const result = await put({
        url: 'tag/filters',
        body: JSON.stringify(defaults),
      });
      console.log(result);
      setSaveDefaultTags(false);
    } catch (error) {
      console.log(error);
    }
  };
  // set previous assessment date to current date as no assessment has ever occurred
  const currentIteration =
    previousAssessmentDates.length > 0
      ? previousAssessmentDates[previousAssessmentDates.length - 1].label
      : new Date().setHours(0, 0, 0, 0);

  const assessmentFilterDates =
    previousAssessmentDates.length > 0 && assessmentFilter.length > 0
      ? previousAssessmentDates.filter(
          (a) => a.value === assessmentFilter[0]
        )[0].label
      : currentIteration;

  // filter data based on if the last_assessment_date is greater than the first elected iteration
  const completed = whichUserData()?.reduce((acc, value) => {
    const thisMonth = value.last_test_date
      ? assessmentFilterDates <= new Date(value.last_test_date)
      : false;
    return thisMonth ? acc + 1 : acc;
  }, 0);
  const incomplete = whichUserData().length - completed;

  const resilienceCounts = whichUserData()
    .filter((u) => u.resilience)
    .reduce((acc, value) => {
      if (!acc[value.resilience]) {
        acc[value.resilience] = 1;
      } else {
        acc[value.resilience]++;
      }
      return acc;
    }, {});

  const histogramResCounts = Object.keys(resilienceCounts)
    .reduce((acc, val) => {
      const count = resilienceCounts[val];
      const bucket = Math.trunc(val / 10);
      acc[bucket] += count;
      return acc;
    }, Array(10).fill(0))
    // if 0 values are null minBarLength will not be set
    .map((n) => (n > 0 ? n : null));

  const safetyCounts = whichUserData()
    .filter((u) => u.safety)
    .reduce((acc, value) => {
      if (!acc[value.safety]) {
        acc[value.safety] = 1;
      } else {
        acc[value.safety]++;
      }
      return acc;
    }, {});

  const hasResilience = Object.keys(resilienceCounts).length > 0;
  const hasSafety = Object.keys(safetyCounts).length > 0;

  const histogramSafCounts = Object.keys(safetyCounts).reduce((acc, val) => {
    const count = safetyCounts[val];
    const bucket = Math.trunc(val / 10);
    acc[bucket] += count;
    return acc;
  }, Array(10).fill(0));

  const testsCompletedData = {
    labels: ['Complete', 'Incomplete'],
    datasets: [
      {
        label: '# of Tests Completed',
        data: [completed, incomplete],
        backgroundColor: [
          theme.palette.primary.main,
          theme.palette.secondary.main,
        ],
        borderColor: [theme.palette.primary.main, theme.palette.secondary.main],
        borderWidth: 1,
      },
    ],
  };

  let width;
  let height;
  let gradient;

  function getGradient(ctx, chartArea, renderAxis) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (gradient === null || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      if (renderAxis === 'x') {
        gradient = ctx.createLinearGradient(
          0,
          chartArea.bottom,
          0,
          chartArea.top
        );
        gradient.addColorStop(0, '#ff0000');
        gradient.addColorStop(1, '#00FF00');
        gradient.addColorStop(0.5, '#FFFF00');
      } else {
        gradient = ctx.createLinearGradient(500, 0, 100, 0);
        gradient.addColorStop(0, 'rgba(255, 0, 0, 1)');
        gradient.addColorStop(0.2, 'rgba(255, 154, 0, 1)');
        gradient.addColorStop(0.35, 'rgba(255, 255, 0,1)');
        gradient.addColorStop(0.6, 'rgba(0, 255, 0, 1)');
        gradient.addColorStop(0.7, 'rgba(255, 255, 0,1)');
        gradient.addColorStop(0.9, 'rgba(255, 154, 0, 1)');
        gradient.addColorStop(1, 'rgba(255, 0, 0, 1)');
      }
    }

    return gradient;
  }

  const backgroundColorArray = [
    'rgba(255, 0, 0, 1)',
    'rgba(255, 0, 0, 1)',
    'rgba(255, 154, 0, 1)',
    'rgba(255, 255, 0, 1)',
    'rgba(144, 255, 0, 1)',
    'rgba(0, 255, 0, 1)',
    'rgba(144, 255, 0, 1)',
    'rgba(255, 255, 0, 1)',
    'rgba(255, 154, 0, 1)',
    'rgba(255, 0, 0, 1)',
  ];

  const resBarData = {
    labels: ['0', '10', '20', '30', '40', '50', '60', '70', '80', '90'],
    datasets: [
      {
        label: 'People',
        data: histogramResCounts,
        // minBarLength only apply to values that are not null
        minBarLength: 12,
        backgroundColor: backgroundColorArray,
      },
    ],
  };

  const resAvg =
    whichUserData()
      .filter((u) => u.resilience)
      .reduce((acc, value) => acc + value.resilience, 0) /
    whichUserData().filter((u) => u.resilience).length;

  const safAvg =
    whichUserData()
      .filter((u) => u.safety)
      .reduce((acc, value) => acc + value.safety, 0) /
    whichUserData().filter((u) => u.safety).length;

  const barData = () => {
    if (hasSafety && hasResilience) {
      return {
        labels: ['Resilience', 'Safety'],
        datasets: [
          {
            data: [resAvg, safAvg],
            backgroundColor: ['rgba(0, 29, 255, 1)', 'rgba(2, 157, 0, 1)'],
            borderColor: ['rgba(0, 29, 255, 1)', 'rgba(2, 157, 0, 1)'],
            borderWidth: 1,
          },
        ],
      };
    }
    if (hasResilience) {
      return {
        labels: ['Resilience'],
        datasets: [
          {
            data: [resAvg],
            backgroundColor: ['rgba(0, 29, 255, 1)'],
            borderColor: ['rgba(0, 29, 255, 1)'],
            borderWidth: 1,
          },
        ],
      };
    }
    return {
      labels: ['Safety'],
      datasets: [
        {
          data: [safAvg],
          backgroundColor: ['rgba(2, 157, 0, 1)'],
          borderColor: ['rgba(2, 157, 0, 1)'],
          borderWidth: 1,
        },
      ],
    };
  };

  const barOptionsCombined = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (e) => `${Math.round((e.parsed.x || 0) * 100) / 100}`,
        },
      },
      legend: {
        display: false,
      },
    },
  };

  const resLineData = {
    datasets: [
      {
        type: 'scatter',
        label: `People`,
        data: groupWellness(whichUserData()),
        borderColor: 'black',
        borderRadius: {
          bottomLeft: 100,
          bottomRight: 100,
          topLeft: 100,
          topRight: 100,
        },
        backgroundColor: ['yellow'],
        fill: false,
        tension: 0.1,
        pointRadius: 8,
      },
      {
        type: 'line',
        pointRadius: 0,
        fill: true,
        label: 'Standard company wellness',
        data: [
          { x: 0, y: 0 },
          { x: 10, y: maxAmountAverageResUsers * 0.05 },
          { x: 20, y: maxAmountAverageResUsers * 0.25 },
          { x: 30, y: maxAmountAverageResUsers * 0.56 },
          { x: 40, y: maxAmountAverageResUsers * 0.88 },
          { x: 50, y: maxAmountAverageResUsers },
          { x: 60, y: maxAmountAverageResUsers * 0.88 },
          { x: 70, y: maxAmountAverageResUsers * 0.56 },
          { x: 80, y: maxAmountAverageResUsers * 0.25 },
          { x: 90, y: maxAmountAverageResUsers * 0.05 },
          { x: 100, y: 0 },
        ],
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            // This case happens on initial chart load
            return null;
          }
          return getGradient(ctx, chartArea, 'y');
        },

        borderColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            // This case happens on initial chart load
            return null;
          }
          return getGradient(ctx, chartArea, 'x');
        },
        tension: 0.5,
      },
    ],
  };

  const lineOptions = {
    borderWidth: 1.5,
    plugins: {
      tooltip: { enabled: true },
      legend: { display: false },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Wellness',
        },
      },
      y: {
        title: {
          display: true,
          text: `Number of ${
            filteredUsersGraphs.length > 0 ? 'submissions' : 'people'
          }`,
        },
      },
    },
  };

  const safBarData = {
    labels: ['< 9', '10', '20', '30', '40', '50', '60', '70', '80', '> 90'],
    datasets: [
      {
        label: 'People',
        data: histogramSafCounts,
        backgroundColor: backgroundColorArray,
      },
    ],
  };

  const barOptions = (xLabel, yLabel) => ({
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          // empty function so title does not display
          title: function () {},
        },
      },
      legend: { display: false },
      title: { display: false },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: xLabel,
        },
      },
      y: {
        title: {
          display: true,
          text: yLabel || 'Number of people',
        },
      },
    },
  });

  // Removes users with no tests completed
  const usersWithSubmissions = whichUserData().filter(
    (u) => u.tests_completed > 0
  );

  const dataSets = () => {
    const datasets = [];
    if (Object.keys(resilienceCounts).length > 0) {
      datasets.push({
        label: 'Users Resilience Avg/Test',
        data: usersWithSubmissions.map((u) => ({
          x: u.avg_resilience,
          y: u.tests_completed,
          label: u.name,
        })),
        backgroundColor: 'rgba(185, 27, 215, 1)',
      });
    }
    if (Object.keys(safetyCounts).length > 0) {
      datasets.push({
        label: 'Users Safety Avg/Test',
        data: usersWithSubmissions.map((u) => ({
          x: u.avg_safety,
          y: u.tests_completed,
          label: u.name,
        })),
        backgroundColor: 'rgba(27, 29, 215, 1)',
      });
    }

    return datasets;
  };

  const page = document.getElementById('page');

  const handleClick = () => {
    html2PDF(page, {
      jsPDF: {
        format: 'a4',
      },
      html2canvas: { scale: 2 },
      imageType: 'image/png',
      output: `report_${format(new Date(), 'dd_MM_yyyy')}.pdf`,
    });
  };

  const handleDialog = async () => {
    // TODO: A circularProgress would be handle here - also should update context
    try {
      const result = await put({
        url: 'accepttc',
      });
      console.log(result);
      dispatchTerms({ type: 'LOAD', terms: new Date() });
    } catch (error) {
      console.log(error);
    }
  };

  const averageSafety = {
    label: 'Safety Average',
    shortLabel: 'Safety',
    data: companyScoreData.map((u) => u.safety),
    fill: false,
    backgroundColor: 'rgba(2, 157, 0, 1)',
    borderColor: 'rgba(2, 157, 0, 1)',
  };

  const averageResilience = {
    label: 'Resilience Average',
    shortLabel: 'Resilience',
    data: companyScoreData.map((u) => u.resilience),
    fill: false,
    backgroundColor: 'rgb(17, 0, 255)',
    borderColor: 'rgba(17, 0, 255, 0.5)',
  };

  const AverageCompanyScores = {
    labels: lastSixIterations,
    datasets:
      // eslint-disable-next-line no-nested-ternary
      hasResilience ? [averageResilience] : [],
  };

  return (
    <>
      <Container maxWidth="lg">
        <DeleteDialog
          handleClose={handleCloseDialog}
          context={
            <Typography>
              Please confirm that you accept the
              <a
                target="_blank"
                href="https://www.cernova.com/terms-of-use/"
                rel="noreferrer"
              >
                {' terms and conditions'}
              </a>
            </Typography>
          }
          title="Cernova Terms of use"
          open={!terms}
          confirmDelete={handleDialog}
        />
        {!windowSmall ? (
          <Tooltip title="Download Report">
            <Fab
              onClick={() => handleClick()}
              className={classes.fab}
              edge="end"
              color="primary"
            >
              <GetApp id="icon_1" />
            </Fab>
          </Tooltip>
        ) : null}
        <Grid
          container
          id="page"
          style={{ marginTop: '2rem', marginBottom: '2rem' }}
        >
          {Object.keys(resilienceCounts).length > 0 ? (
            <Grid item xs={6} className={classes.grid}>
              <Card className={classes.card}>
                <CardHeader
                  title="Wellness Trend"
                  color="white"
                  className={classes.CardHeader}
                  {...titleTypographyProps}
                />
                <Tooltip title="Employees plotted compared to standardized bell curve">
                  <CardContent>
                    <Bubble data={resLineData} options={lineOptions} />
                  </CardContent>
                </Tooltip>
              </Card>
            </Grid>
          ) : null}
          {Object.keys(resilienceCounts).length > 0 ? (
            <Grid item xs={6} className={classes.grid}>
              <Card className={classes.card}>
                <CardHeader
                  title="Resilience Trend"
                  className={classes.cardTitle}
                  {...titleTypographyProps}
                />
                <Tooltip title="Employees resilience results">
                  <CardContent>
                    <Bar data={resBarData} options={barOptions('Resilience')} />
                  </CardContent>
                </Tooltip>
              </Card>
            </Grid>
          ) : null}
          {Object.keys(safetyCounts).length > 0 ? (
            <Grid item xs={6} className={classes.grid}>
              <Card className={classes.card}>
                <CardHeader
                  title="Safety Scores"
                  className={classes.cardTitle}
                  {...titleTypographyProps}
                />
                <Tooltip title="Employees safety results">
                  <CardContent>
                    <Bar data={safBarData} options={barOptions('Safety')} />
                  </CardContent>
                </Tooltip>
              </Card>
            </Grid>
          ) : null}

          <Grid item xs={6} className={classes.grid}>
            <Card className={classes.card}>
              <CardHeader
                className={classes.cardTitle}
                title="Average Score"
                {...titleTypographyProps}
              />
              <CardContent className={classes.CardContent}>
                <Bar data={barData} options={barOptionsCombined} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6} className={classes.grid}>
            <Card className={classes.card}>
              <CardHeader
                className={classes.cardTitle}
                title="Submissions"
                {...titleTypographyProps}
              />
              <CardContent className={classes.CardContent}>
                <Pie
                  className={classes.pie}
                  data={testsCompletedData}
                  options={{
                    plugins: {
                      tooltip: { enabled: true },
                      legend: { display: false },
                      datalabels: {
                        display: true,
                        color: theme.palette.primary.contrastText,
                        backgroundColor: theme.palette.primary.main,
                        borderColor: theme.palette.primary.dark,
                        borderRadius: 25,
                        borderWidth: 3,
                        labels: {
                          title: {
                            font: {
                              weight: 'bold',
                            },
                          },
                        },
                        formatter: (e) =>
                          e === completed
                            ? `${Math.round(
                                (100 / usersAmount) * completed
                              )} % completed`
                            : null,
                      },
                    },
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} className={classes.grid}>
            <Card className={classes.card}>
              <CardHeader
                title="Average Score Timeline"
                className={classes.cardTitle}
                {...titleTypographyProps}
              />
              <Tooltip title="Average Resilience">
                <CardContent>
                  <Line
                    data={AverageCompanyScores}
                    options={{
                      plugins: {
                        tooltip: {
                          enabled: true,
                          callbacks: {
                            // e.dataset.shortLabel is custom key in datasetObject
                            label: (e) =>
                              `${e.dataset.shortLabel}: ${e.parsed.y}`,
                          },
                        },
                        legend: {
                          display: true,
                        },
                      },
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                </CardContent>
              </Tooltip>
            </Card>
          </Grid>

          {Array.isArray(form) && form.length > 0 && (
            <QuestionCharts
              questions={form || []}
              questionData={formAnswers || {}}
            />
          )}
        </Grid>

        <Toolbar className={classes.toolbar} ref={toolbarRef}>
          <div className={classes.headSearch}>
            <IconButton
              className={classes.iconButton}
              aria-label="Search"
              size="large"
            >
              <Search />
            </IconButton>
            <InputBase
              className={classes.input}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search..."
            />
          </div>
          <Tooltip title="Filter">
            <FormControlLabel
              control={
                <IconButton
                  edge={false}
                  aria-label="View"
                  onClick={handleClickTag}
                  className={classes.iconButton}
                  size="large"
                >
                  <FilterAltIcon />
                </IconButton>
              }
              label="Filter"
              labelPlacement="end"
              color="secondary"
            />
          </Tooltip>
          <Tooltip title="Create user">
            <FormControlLabel
              control={
                <IconButton
                  edge={false}
                  aria-label="View"
                  onClick={() => setUserDraw(!userDraw)}
                  className={classes.iconButton}
                  size="large"
                >
                  <PersonAdd />
                </IconButton>
              }
              label="Create"
              labelPlacement="end"
              color="secondary"
            />
          </Tooltip>

          <Tooltip title="Whatever has been filtered to will be downloaded to a csv">
            <FormControlLabel
              control={
                <IconButton
                  edge={false}
                  aria-label="View"
                  onClick={() =>
                    csvDownload({
                      data: filteredUsers,
                      fileName: `company_filtered_users_${format(
                        new Date(),
                        'dd_MM_yyyy'
                      )}`,
                      keepExtras: true,
                      safety: details.safety,
                      resilience: details.resilience,
                    })
                  }
                  className={classes.iconButton}
                  size="large"
                >
                  <GridOn />
                </IconButton>
              }
              label="Download Results"
              labelPlacement="end"
              color="secondary"
            />
          </Tooltip>
          <Tooltip title={`${tagsDraw ? 'Hide' : 'Show'} tags`}>
            <FormControlLabel
              control={
                <Switch
                  icon={
                    <LabelOffTwoTone
                      className={classes.icon}
                      fontSize="medium"
                    />
                  }
                  checkedIcon={
                    <Label className={classes.icon} fontSize="medium" />
                  }
                  checked={tagsDraw}
                  name="tag-switch"
                  onChange={() => setTagsDraw(!tagsDraw)}
                  className={classes.switch}
                />
              }
              label="Tags"
              style={{ flex: 1 }}
            />
          </Tooltip>
          {identifiedData && (
            <Tooltip title={`${checked ? 'Hide' : 'Show'} User Details`}>
              <FormControlLabel
                control={
                  <Switch
                    icon={
                      <DescriptionTwoTone
                        color="disabled"
                        className={classes.icon}
                        fontSize="medium"
                      />
                    }
                    checkedIcon={
                      <Description className={classes.icon} fontSize="medium" />
                    }
                    checked={checked}
                    onChange={() => changeTableFilter()}
                    name="checked"
                    className={classes.switch}
                  />
                }
                label="User Details"
                style={{ flex: 1 }}
              />
            </Tooltip>
          )}
        </Toolbar>
        <Popover
          id={tagPopoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: { width: toolbarWidth },
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            className={classes.popOverGridContainer}
          >
            {tags.length >= 2
              ? tags.map((tag, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid item xs={4} key={index}>
                    <FormControl className={classes.formControl}>
                      <Card>
                        <CardHeader
                          title={
                            <Typography sx={{ marginLeft: '0.5rem' }}>
                              {tag.question}
                            </Typography>
                          }
                          className={classes.TagCardHeader}
                        />

                        <Select
                          fullWidth
                          multiple
                          name={tag.tag_group_id.toString()}
                          value={filterObject[tag.tag_group_id] || []}
                          onChange={(e) => {
                            updateFilter(tag, e.target.value, e.target.name, e);
                          }}
                          input={<Input />}
                          renderValue={(selected) =>
                            tag.options &&
                            tag.options
                              .filter((t) => selected.includes(t.value))
                              .map((o) => o.label)
                              .join(', ')
                          }
                        >
                          {tag.options &&
                            tag.options.map((t) => (
                              <MenuItem key={t.label} value={t.value}>
                                <Checkbox
                                  checked={
                                    filterObject[tag.tag_group_id]
                                      ? filterObject[tag.tag_group_id].includes(
                                          t.value
                                        )
                                      : false
                                  }
                                />
                                <ListItemText primary={t.label} />
                              </MenuItem>
                            ))}
                        </Select>
                      </Card>
                    </FormControl>
                  </Grid>
                ))
              : null}
            {saveDefaultTags && (
              <Grid item xs={4} key="index">
                <FormControl className={classes.formControl}>
                  <Card>
                    <Button
                      fullWidth
                      className={classes.button}
                      onClick={() => commitDefaultTags(filterObject)}
                    >
                      Save Defaults
                    </Button>
                  </Card>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Popover>
        {userDraw && <UserForm collectionOfTags={tags} />}

        <Card
          style={{
            paddingTop: '0.4rem',
            paddingBottom: '0.6rem',
          }}
        >
          <Chip
            key="active-filters-chip"
            className={classes.filterChip}
            label="Active Filters"
            size="medium"
            avatar={
              Object.values(filterObject)
                .map((u) => u)
                .flat().length > 0 ? (
                <FilterAltIcon />
              ) : (
                <FilterAltOffIcon />
              )
            }
            onClick={handleClickTag}
          />

          {Object.keys(filterObject).length > 0 &&
            Object.keys(filterObject).map((key) => (
              <>
                {tags
                  .filter((w) => w.tag_group_id === parseInt(key, 10))
                  .map((o) =>
                    o.options
                      .filter((v) => filterObject[key].includes(v.value))
                      .map((t) => (
                        <Chip
                          key={`${t.value}${key}`}
                          className={classes.filterChip}
                          label={t.label || ''}
                          clickable={false}
                          onDelete={() => {
                            updateFilter(
                              o,
                              filterObject[key].filter((u) => u !== t.value),
                              key
                            );
                          }}
                        />
                      ))
                  )}
              </>
            ))}
        </Card>
        {tagsDraw && <Tags reload={reload} collectionOfTags={tags} />}
        <UserAnalyticsTable
          users={filteredUsers}
          hasResilience={hasResilience}
          hasSafety={hasSafety}
          tableFilter={tableFilter}
        />
      </Container>
    </>
  );
}
