import React, { useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import format from 'date-fns/format';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAssessmentContext } from '../context/AssessmentContext';
import useApi from '../functions/useApi';
import { useAlertContext } from '../context/AlertContext';
import TextInput from '../components/FormInputText';
import FormikForm from '../components/FormikForm';
import Select from '../components/FormInputSelect';
import FormButton from '../components/FormButton';
import { csvDownload } from '../functions/csvDownload';
import { useUsersContext } from '../context/UsersContext';

const style = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    marginTop: 40,
    right: 40,
  },
  grid: { marginTop: '1rem' },
  listItem: {
    backgroundColor: theme.palette.primary.dark,
    colors: theme.palette.secondary.light,
    marginTop: '0.2rem',
  },
  listItemText: {
    color: theme.palette.primary.contrastText,
  },
}));

export default function TestingBoard(props) {
  const { form, nextAssessmentDate, futureAssessmentDates } =
    useAssessmentContext();
  const { to } = props;
  const classes = style();
  const { post, get } = useApi();
  //   const [name, setName] = useState('defaultName');
  const alertContext = useAlertContext();
  const [open, setOpen] = useState(false);

  const renderLink = React.forwardRef((itemProps, ref) => (
    // with react-router-dom@^5.0.0 use `ref` instead of `innerRef`
    <RouterLink
      to={to}
      {...itemProps}
      activeclassname={classes.active}
      innerRef={ref}
    />
  ));

  const formik = useFormik({
    initialValues: {
      name: 'DefaultName',
      email: '',
      companyDesc: 'some company description',
      type: 'welcomeClient',
      testId: '1',
      resPercent: '10',
      resColor: '10',
      companyName: 'Company XYZ',
      companyId: '1',
      phoneNumber: '',
      sms: '',
      iteration: '1',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('enter name'),
      email: Yup.string().required('enter email'),
      companyDesc: Yup.string().required('enter description'),
      type: Yup.string().required('select type'),
      testId: Yup.string().required('select type'),
      resPercent: Yup.string().required('select type'),
      resColor: Yup.string().required('select type'),
      companyName: Yup.string().required('select type'),
      companyId: Yup.string().required('company id'),
      phoneNumber: Yup.string(),
      sms: Yup.string(),
      iteration: Yup.string(),
    }),

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      console.log(values);

      try {
        const res = await post({
          url: 'test-email',
          body: values,
        });
        console.log(res);
        alertContext.success(`${values.type} has been sent`);
      } catch (error) {
        console.error(error);
        alertContext.error('An error occurred failed to send');
      }
      setSubmitting(false);
    },
  });

  const results = async () => {
    let cursor = 0;
    let partsOfUsers = [];

    while (cursor || cursor === 0) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const res = await get({
          url: `users/${cursor}/${formik.values.iteration}`,
        });

        if (res.cursor > 0) {
          cursor = res.cursor;
        } else cursor = false;

        if (Array.isArray(res.message)) {
          partsOfUsers = [
            ...partsOfUsers,
            ...res.message.map((u) => ({
              ...u,
              ...(u.tags && { tags: JSON.parse(u.tags) }),
              ...(u.results && { results: JSON.parse(u.results) }),
            })),
          ];
        }
      } catch (error) {
        console.error(error);
        // TODO add retry
        // dispatchUsers({
        //   type: 'LOAD_USERS',
        //   users: [],
        // });
      }
    }
    console.log(partsOfUsers);
    return partsOfUsers;
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <Container>
      <List>
        <ListItem
          button
          key="Dashboard"
          component={renderLink}
          to="/assessment"
          onClick={() => handleDrawerOpen()}
          variant="contained"
          className={classes.listItem}
        >
          <ListItemText
            primary="Go to assessment"
            className={classes.listItemText}
          />
        </ListItem>
        <ListItem
          button
          key="Dashboard"
          onClick={async () => {
            await post({
              url: 'users/invite',
            });
          }}
          variant="contained"
          className={classes.listItem}
        >
          <ListItemText
            primary="Send invites"
            className={classes.listItemText}
          />
        </ListItem>
        <ListItem
          button
          key="Download Users"
          onClick={async () => {
            // console.log(results());
            csvDownload({
              data: await results(),
              fileName: `company_users_${format(new Date(), 'dd_MM_yyyy')}`,
            });
          }}
          variant="contained"
          className={classes.listItem}
        >
          <ListItemText
            primary="Download users"
            className={classes.listItemText}
          />
        </ListItem>
      </List>
      <FormikForm formik={formik}>
        <Grid container id="testBoard">
          <Grid className={classes.grid} item xs={12} sm={6} md={6} lg={6}>
            <Typography variant="h4">Email Testing</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextInput
              key="name"
              fullWidth
              id="name"
              name="name"
              label="name"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextInput
              key="email"
              fullWidth
              id="email"
              name="email"
              label="email"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextInput
              key="phoneNumber"
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              label="phoneNumber"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextInput
              key="companyDesc"
              fullWidth
              id="companyDesc"
              name="companyDesc"
              label="company Description"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextInput
              key="testId"
              fullWidth
              id="testId"
              name="testId"
              label="test ID"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextInput
              key="sms"
              fullWidth
              id="sms"
              name="sms"
              label="SMS message"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextInput
              key="companyName"
              fullWidth
              id="companyName"
              name="companyName"
              label="companyName"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Select
              constantLabel
              options={['10', '20', '30', '45', '50', '55', '70', '90']}
              // eslint-disable-next-line camelcase
              key="resPercent"
              label="resPercent" // label
              name="resPercent" // formik name
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextInput
              key="companyId"
              fullWidth
              id="companyId"
              name="companyId"
              label="companyId"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextInput
              key="resColor"
              fullWidth
              id="resColor"
              name="resColor"
              label="resColor"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Select
              constantLabel
              options={[
                'welcomeClient',
                'welcomeEmployee',
                'formSubmission',
                'assessmentInvite',
                'assessmentReminder',
                'tipSheet',
                'lowResilienceEmail',
                'lowSafetyEmail',
                'sms',
                // 'lowScore',
                // 'nonCompletion',
              ]}
              key="type"
              label="type" // label
              name="type" // formik name
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Select
              constantLabel
              options={['1', '2', '3', '4', '5', '6', '7', '8', '9']}
              key="iteration"
              label="iteration" // label
              name="iteration" // formik name
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormButton
              customStyle={{ marginTop: '2rem' }}
              fullWidth
              text="submit"
              loading={formik.isSubmitting}
              noScroll
            />
          </Grid>
        </Grid>
      </FormikForm>
    </Container>
  );
}
