/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useLayoutEffect, useEffect } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from '@mui/material/styles';
import Routes from './Routes';
import SiteWrapper from './components/SiteWrapper';
import LoadingBackground from './components/LoadingBackground';
import useApi from './functions/useApi';
import AuthContextProvider from './context/AuthContext';
import AlertContextProvider from './context/AlertContext';
import UsersContextProvider from './context/UsersContext';
import NotificationsContextProvider from './context/NotificationsContext';
import SessionContexts from './context/SessionContexts';
import { analyticsPage } from './constants/routeWithParams';

function useWindowWidth() {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    window.addEventListener('orientationchange', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function App() {
  const history = useHistory();

  const { isLoading, isAuthenticated, user, getAccessTokenSilently } =
    useAuth0();
  const [userD, setUserD] = useState({
    theme_primary: '#142637',
    theme_secondary: '#99ccff',
  });

  const redirect = new URLSearchParams(useLocation().search).get('redirect');

  const checkLogin = async () => {
    if (redirect === '1') {
      const token = await getAccessTokenSilently({
        audience: 'https://cernova-api-gateway.com',
      });
    }
  };

  const [themePrimary, setThemePrimary] = useState('#142637');
  const [themeSecondary, setThemeSecondary] = useState('#99ccff');
  const [enrolled, setEnrolled] = useState(true);
  const [loading, setLoading] = useState(true);

  const windowSmall = useWindowWidth() < 550;
  const screenWidth = useWindowWidth();
  const { get } = useApi();

  const changePrimaryColor = (color) => {
    if (color) setThemePrimary(color);
  };

  const changeSecondaryColor = (color) => {
    if (color) setThemeSecondary(color);
  };

  const updateQuestionDate = () => {
    const updateUserD = userD;
    updateUserD.questiondate = new Date().toISOString();
    setUserD({ ...updateUserD });
  };

  // TODO refine loading script
  useEffect(async () => {
    if (!isLoading) {
      checkLogin();
      if (isAuthenticated) {
        const result = await get({ url: 'login' });
        setUserD(result.message);
        if (result.enrolled === false) {
          setEnrolled(false);
        }
        changePrimaryColor(result.message.theme_primary);
        changeSecondaryColor(result.message.theme_secondary);
        // TODO: Change logo here too (result.message.logo_link)
      }
      setLoading(false);
    }
  }, [isLoading, isAuthenticated, redirect]);
  // new pass
  const childProps = {
    userDetails: userD,
    isAdmin: userD?.is_admin,
    isCernova: userD?.is_cernova,
    userTypes: [
      userD?.is_cernova === true && 'cernova',
      userD?.is_admin === true && 'company-administrator',
      !userD.is_admin && !userD.is_cernova && userD?.user_id && 'company-user',
    ],
    companyName: userD?.name,
    user: user,
    windowSmall: windowSmall,
    screenWidth: screenWidth,
    changePrimaryColor: changePrimaryColor,
    changeSecondaryColor: changeSecondaryColor,
    updateQuestionDate: updateQuestionDate,
    logoLink: userD.logo_link,
  };

  useEffect(() => {
    if (userD?.is_cernova) {
      history.push('/dashboard');
    } else if (userD?.is_admin && !userD.is_cernova) {
      history.push(analyticsPage);
    } else if (userD.is_admin === false && userD.is_cernova === false) {
      history.push('/assessment');
    } else if (!enrolled) {
      history.push('/no-user');
    }
  }, [userD, enrolled]);

  const customTheme = createTheme({
    palette: {
      primary: {
        main: themePrimary,
      },
      secondary: {
        main: themeSecondary,
      },
      error: {
        main: '#e33371',
      },
    },
  });

  if (!loading)
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={customTheme}>
          <div className="App">
            <AlertContextProvider>
              <AuthContextProvider authContext={childProps}>
                <NotificationsContextProvider>
                  <SessionContexts>
                    <UsersContextProvider>
                      {!isLoading ? (
                        <SiteWrapper {...childProps}>
                          <Routes
                            isAuthenticated={isAuthenticated}
                            childProps={childProps}
                          />
                        </SiteWrapper>
                      ) : (
                        <LoadingBackground visible />
                      )}
                    </UsersContextProvider>
                  </SessionContexts>
                </NotificationsContextProvider>
              </AuthContextProvider>
            </AlertContextProvider>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  return (
    <div>
      <LoadingBackground visible />
    </div>
  );
}

export default withRouter(App);
