/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect, useState, useReducer } from 'react';
import useApi from '../functions/useApi';
// eslint-disable-next-line import/no-named-as-default
import {
  TermsReducer,
  SmsReducer,
  DefaultTagFiltersReducer,
  LogoReducer,
  TimeZoneReducer,
  TipSheetReducer,
  HelpRequestReducer,
  helpRequestRecipientsReducer,
} from './CompanyReducer';

export const CompanyContext = React.createContext({});

export function useCompanyContext() {
  return useContext(CompanyContext);
}

const CompanyContextProvider = (props) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState([]);
  const [details, setDetails] = useState({});
  const [identifiedData, setIdentifiedData] = useState(true);
  const [logoLink, dispatchLogoLink] = useReducer(LogoReducer, []);
  const [terms, dispatchTerms] = useReducer(TermsReducer, []);
  const [smsEnabled, dispatchSmsEnabled] = useReducer(SmsReducer, []);
  const [tipSheetEnabled, dispatchTipSheetEnabled] = useReducer(
    TipSheetReducer,
    []
  );
  const [helpRequestEnabled, dispatchHelpRequestEnabled] = useReducer(
    HelpRequestReducer,
    []
  );
  const [helpRequestRecipients, dispatchHelpRequestRecipients] = useReducer(
    helpRequestRecipientsReducer,
    []
  );
  const [timeZone, dispatchTimeZone] = useReducer(TimeZoneReducer, []);
  const [defaultTagFilters, dispatchDefaultTagFilters] = useReducer(
    DefaultTagFiltersReducer,
    []
  );
  const [refresh, setRefresh] = useState(false);

  const { get } = useApi();

  function reload() {
    setRefresh(!refresh);
  }

  const companyName = details?.name || null;

  useEffect(async () => {
    let isCancelled = false;

    console.log('Loading company');
    setLoading(true);

    try {
      const res = await get({ url: 'company/details' });
      console.log(res.message);
      setDetails(res.message);
      setIdentifiedData(res.message.identifiedData);
      dispatchTerms({ type: 'LOAD', terms: res.message.tcAccepted });
      dispatchSmsEnabled({ type: 'LOAD', sms: res.message.smsEnabled });
      dispatchTipSheetEnabled({
        type: 'LOAD',
        tipSheetEnabled: res.message.tipSheetEnabled,
      });
      dispatchHelpRequestEnabled({
        type: 'LOAD',
        helpRequestEnabled: res.message.helpRequestEnabled,
      });
      dispatchHelpRequestRecipients({
        type: 'LOAD',
        helpRequestRecipients: res.message.helpRequestRecipients.data,
      });
      dispatchTimeZone({ type: 'LOAD', value: res.message.timeZone });
      dispatchDefaultTagFilters({
        type: 'LOAD',
        tagFilters: res.message.defaultTagFilters || {},
      });
      dispatchLogoLink({
        type: 'LOAD',
        logoLink: res.message.logoLink,
      });
    } catch (error) {
      console.error(error);
    }

    return () => {
      isCancelled = true;
    };
  }, [refresh]);

  return (
    <CompanyContext.Provider
      value={{
        terms,
        details,
        dispatchTerms,
        loading,
        reload,
        companyName,
        tipSheetEnabled,
        dispatchTipSheetEnabled,
        smsEnabled,
        dispatchSmsEnabled,
        defaultTagFilters,
        dispatchDefaultTagFilters,
        logoLink,
        dispatchLogoLink,
        timeZone,
        identifiedData,
        helpRequestEnabled,
        dispatchHelpRequestEnabled,
        helpRequestRecipients,
        dispatchHelpRequestRecipients,
      }}
    >
      {props.children}
    </CompanyContext.Provider>
  );
};

export default CompanyContextProvider;
