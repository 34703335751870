/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ListItemIcon from '@mui/material/ListItemIcon';
import Chat from '@mui/icons-material/Chat';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Poll from '@mui/icons-material/Poll';
import Menu from '@mui/icons-material/Menu';
import Beenhere from '@mui/icons-material/Beenhere';
import Input from '@mui/material/Input';
import Person from '@mui/icons-material/Person';
import PieChart from '@mui/icons-material/PieChart';
import DeveloperBoard from '@mui/icons-material/DeveloperBoard';
import Assignment from '@mui/icons-material/Assignment';
import Settings from '@mui/icons-material/Settings';
import Schedule from '@mui/icons-material/Schedule';
import CloudUpload from '@mui/icons-material/CloudUpload';
import CloudDownload from '@mui/icons-material/CloudDownload';
import MultilineChartRounded from '@mui/icons-material/MultilineChartRounded';
import AssignmentTurnedIn from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentLate from '@mui/icons-material/AssignmentLate';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Grow from '@mui/material/Grow';
import Sms from '@mui/icons-material/Sms';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import CircularProgress from '@mui/material/CircularProgress';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import format from 'date-fns/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import Select from '@mui/material/Select';
import Assessment from '@mui/icons-material/Assessment';
import Dialog from '@mui/material/Dialog';
import { csvDownload } from '../functions/csvDownload';
import { useAuthContext } from '../context/AuthContext';
import { UserTypeObject } from '../constants/UserTypes';
import logo from '../assets/cernova.png';
import SettingsDialog from './SettingsDialog';
import Scheduler from './Scheduler';
import { UsersContext } from '../context/UsersContext';
import {
  useAssessmentContext,
  AssessmentContext,
} from '../context/AssessmentContext';
import { useCompanyContext, CompanyContext } from '../context/CompanyContext';
import useApi from '../functions/useApi';
import { analyticsPage } from '../constants/routeWithParams';

// TODO: Fix Footer bug
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  menuButton: {
    marginLeft: 12,
    [theme.breakpoints.up('md')]: {
      marginRight: 36,
    },
    color: theme.palette.primary.contrastText,
  },
  palette: {
    primary: { main: '#fefefe' },
  },
  primaryContrast: {
    color: theme.palette.primary.contrastText,
  },
  title: {
    flexGrow: 1,
  },
  appBar: { zIndex: theme.zIndex.drawer + 1 },
  drawer: {
    width: 240,
  },
  drawerList: {
    backgroundColor: '#FFFFFF',
    width: 240,
    height: '100%',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  grow: {
    flexGrow: 1,
  },
  header: {
    color: '#21761B',
  },
  drawerText: {
    color: '#747474',
  },
  white: {
    color: '#FFFFFF',
  },
  active: {
    backgroundColor: 'rgba(0, 130, 200, 0.3)',
  },
  footer: {
    width: '100%',
    height: 20,
    backgroundColor: '#F5F5F5',
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    position: 'fixed',
    left: 0,
    top: 'auto',
    bottom: 0,
    right: 0,
  },
  info: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
  },
  menuLogo: {
    width: '95%',
    marginTop: '4.2rem',
    marginBottom: 10,
    marginLeft: 4,
  },
  csvDownload: {
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  },
  csvUpload: {
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  },
  smsDisabled: {
    color: theme.palette.secondary.main,
  },
  assessmentFilter: {
    color: theme.palette.primary.contrastText,
    borderWidth: '0.15rem',
    borderStyle: 'solid',
    borderRadius: '0.4rem',
    width: '16rem',
  },
  progress: { color: theme.palette.primary.contrastText, marginLeft: '0.2rem' },
}));

export default function SiteWrapper(props) {
  const classes = useStyles();
  const { smsEnabled, dispatchSmsEnabled } = useContext(CompanyContext);
  const { dispatchAssessmentFilter } = useContext(AssessmentContext);
  const [smsLoading, setSmsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [scheduleDatesOpen, setScheduleDatesOpen] = useState(false);
  const { users, usersLoading } = useContext(UsersContext);
  const [currentFilters, setCurrentFilters] = useState({});
  const [query, setQuery] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [schedulerOpen, setSchedulerOpen] = useState(false);
  const [toFilter, setToFilter] = useState(1);
  const [fromFilter, setFromFilter] = useState(1);
  const [selectedAssessments, setSelectedAssessments] = useState([]);
  const authContext = useAuthContext();
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const { userDetails, changePrimaryColor, changeSecondaryColor, screenWidth } =
    props;
  const [daysRemaining, setDaysRemaining] = useState(0);
  const [questionsOK, setQuestionsOK] = useState(false);
  const anchorRef = React.useRef(null);
  const {
    nextAssessmentDate,
    futureAssessmentDates,
    form,
    previousAssessmentDates,
  } = useAssessmentContext();
  const { companyName, logoLink, timeZone } = useCompanyContext();
  const { put } = useApi();
  const path = useLocation().pathname;

  const previousAssessmentDatesPopulated = previousAssessmentDates
    ? previousAssessmentDates.length > 0
    : false;

  const renderLink = React.forwardRef((itemProps, ref) => (
    // with react-router-dom@^5.0.0 use `ref` instead of `innerRef`
    <RouterLink
      to={props.to}
      {...itemProps}
      activeclassname={classes.active}
      innerRef={ref}
    />
  ));

  const handleSms = async () => {
    setSmsLoading(true);
    try {
      const result = await put({
        url: 'sms',
        body: !smsEnabled,
      });
      console.log(result);
      dispatchSmsEnabled({ type: 'LOAD', sms: !smsEnabled });
    } catch (error) {
      console.error(error);
    }
    setSmsLoading(false);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const LoginButton = () => (
    <Button
      variant="contained"
      className={classes.info}
      onClick={() => loginWithRedirect()}
    >
      Login
    </Button>
  );

  const handleScheduleDatesClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setScheduleDatesOpen(false);
  };

  const filteredSearch = () => {
    if (users.length > 0) {
      return users?.filter(
        (user) =>
          JSON.stringify(user).toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return [];
  };

  const filteredUsers = filteredSearch()?.filter((u) => {
    const userTags = u.tags || [];
    return !Object.keys(currentFilters).find(
      (f) =>
        !(
          !currentFilters[f] ||
          userTags.find(
            (ut) => ut.tag_group === f && ut.tag === currentFilters[f]
          )
        )
    );
  });

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setScheduleDatesOpen(false);
    }
  }

  const LogoutButton = () => (
    <Button
      variant="contained"
      className={classes.info}
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      Logout
    </Button>
  );

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const cernova = authContext.hasUserType(UserTypeObject.CERNOVA);
  const admin = authContext.hasUserType(UserTypeObject.COMPANY_ADMINISTRATOR);
  const user = authContext.hasUserType(UserTypeObject.COMPANY_USER);

  useEffect(() => {
    // TODO overview questionDate is set correctly
    if (userDetails.questiondate) {
      const days = Math.round(
        183 - (new Date() - new Date(userDetails.questiondate)) / 86400000
      );
      // console.log(days > 0 ? days : 0);
      setDaysRemaining(days > 0 ? days : 0);

      setQuestionsOK(days > 0);
    } else {
      setDaysRemaining(0);
      setQuestionsOK(false);
    }
  }, [userDetails]);

  const homeRoute = () => {
    if (cernova) {
      return '/dashboard';
    }
    if (admin) {
      return analyticsPage;
    }
    if (user) {
      return '/assessment';
    }
    return '/login';
  };

  return (
    <div>
      <div className={classes.root}>
        <AppBar elevation={1} position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              id="menuButton"
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={classes.menuButton}
              size="large"
            >
              <Badge color="secondary" variant="dot" invisible={questionsOK}>
                <Menu id="Menu" className={classes.white} />
              </Badge>
            </IconButton>
            {screenWidth > 810 && (
              <div style={{ flex: 3, fontSize: '1.3rem' }}>
                {companyName || 'Cernova'}
              </div>
            )}
            {(admin || cernova) && isAuthenticated && (
              <>
                {path === '/analytics' && (
                  <div className={classes.assessmentFilter}>
                    <Tooltip
                      placement="left-end"
                      title="Graphs will be filtered based on selected assessments"
                    >
                      <Select
                        style={{ width: '100%' }}
                        className={classes.primaryContrast}
                        multiple
                        disableUnderline
                        name="Assessment Filter"
                        value={selectedAssessments}
                        onChange={(v) => {
                          setSelectedAssessments(v.target.value);
                          dispatchAssessmentFilter({
                            type: 'LOAD',
                            filter: v.target.value,
                          });
                        }}
                        input={<Input />}
                        renderValue={(selected) =>
                          selected
                            .map((v) =>
                              format(
                                previousAssessmentDates.map((t) => t.label)[
                                  v - 1
                                ],
                                'dd/MM/yy'
                              )
                            )
                            .join(', ')
                        }
                      >
                        {previousAssessmentDates.map((v) => (
                          <MenuItem key={v.value} value={v.value}>
                            <Checkbox
                              checked={selectedAssessments.includes(v.value)}
                            />
                            {format(v.label, 'dd/MM/yy')}
                            <Assignment
                              id="Menu"
                              color="primary"
                              style={{ paddingLeft: '1rem' }}
                            />
                            {v.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  </div>
                )}
                <Tooltip title="Upload Users">
                  <IconButton
                    edge="end"
                    aria-label="View"
                    color="primary"
                    component={RouterLink}
                    to="/upload/users"
                    className={classes.csvUpload}
                    style={{ marginRight: 0 }}
                    size="large"
                  >
                    <CloudUpload />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Download current users">
                  <FormControlLabel
                    control={
                      <IconButton
                        edge={false}
                        aria-label="View"
                        onClick={() => {
                          csvDownload({
                            data: filteredUsers,
                            fileName: `company_users_${format(
                              new Date(),
                              'dd_MM_yyyy'
                            )}`,
                          });
                        }}
                        className={classes.csvDownload}
                        size="large"
                      >
                        <CloudDownload />
                        <Person />
                        {screenWidth > 810 ? 'Users ' : ''}
                        {usersLoading && (
                          <CircularProgress
                            className={classes.progress}
                            size={26}
                          />
                        )}
                        {!usersLoading && users?.length}{' '}
                      </IconButton>
                    }
                    // label={`Current users: ${users?.length}`}
                    labelPlacement="end"
                    color="secondary"
                  />
                </Tooltip>
                {screenWidth > 810 && (
                  <IconButton
                    id="menuButton"
                    aria-label="Open drawer"
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={() =>
                      authContext.userDetails.next_assessment_date
                        ? setScheduleDatesOpen(!scheduleDatesOpen)
                        : setSchedulerOpen(true)
                    }
                    className={classes.menuButton}
                    size="large"
                  >
                    <Assignment id="Menu" className={classes.white} />
                    {nextAssessmentDate
                      ? format(
                          utcToZonedTime(nextAssessmentDate, timeZone),
                          'dd/MM/yyyy'
                        )
                      : 'Please set schedule'}
                  </IconButton>
                )}
              </>
            )}
            <Popper
              open={scheduleDatesOpen}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleScheduleDatesClose}>
                      <MenuList
                        autoFocusItem={scheduleDatesOpen}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem
                          key="AssessmentsLabel"
                          onClick={handleScheduleDatesClose}
                        >
                          Assessments
                        </MenuItem>
                        {futureAssessmentDates.map((x) => (
                          <MenuItem
                            key={x.toString()}
                            onClick={handleScheduleDatesClose}
                          >
                            {format(x, 'dd/MM/yyyy')}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>

            {isAuthenticated && <LogoutButton />}
            {!isAuthenticated && !path.startsWith('/a/') && <LoginButton />}
          </Toolbar>
        </AppBar>
        <SwipeableDrawer
          className={classes.drawer}
          anchor="left"
          open={Boolean(open)}
          onClose={() => handleDrawerOpen()}
          onOpen={() => setOpen(true)}
        >
          <div className={classes.drawerList}>
            <div style={{ width: '100%', backgroundColor: '#FFFFFF' }}>
              <img
                id="menu_logo"
                src={logoLink || logo}
                alt="img_logo"
                className={classes.menuLogo}
              />
            </div>
            <Divider />
            <List>
              {cernova && (
                <ListItem
                  button
                  key="Dashboard"
                  component={renderLink}
                  to={homeRoute}
                  onClick={() => handleDrawerOpen()}
                >
                  <ListItemIcon>
                    <MultilineChartRounded />
                  </ListItemIcon>
                  <ListItemText
                    primary="Manage Companies"
                    className={classes.drawerText}
                  />
                </ListItem>
              )}
              {admin && (
                <>
                  <ListItem
                    button
                    key="analytics"
                    component={renderLink}
                    to={analyticsPage}
                    onClick={() => handleDrawerOpen()}
                  >
                    <ListItemIcon>
                      <PieChart />
                    </ListItemIcon>
                    <ListItemText
                      primary="Analytics"
                      className={classes.drawerText}
                    />
                  </ListItem>
                </>
              )}
              {!admin && !cernova && user && (
                <ListItem
                  button
                  key="assessment"
                  component={renderLink}
                  to="/assessment"
                  onClick={() => handleDrawerOpen()}
                >
                  <ListItemIcon>
                    <Poll />
                  </ListItemIcon>
                  <ListItemText
                    primary="Assessment"
                    className={classes.drawerText}
                  />
                </ListItem>
              )}

              {cernova && (
                <ListItem
                  button
                  key="questionbuilder"
                  component={renderLink}
                  to="/question-builder"
                  onClick={() => handleDrawerOpen()}
                >
                  <ListItemIcon>
                    <Beenhere />
                  </ListItemIcon>
                  <ListItemText
                    primary="Question Builder"
                    className={classes.drawerText}
                  />
                </ListItem>
              )}
              {(admin || cernova) && (
                <>
                  <Tooltip
                    title={
                      questionsOK
                        ? 'Questions Overview'
                        : 'Question selection open'
                    }
                  >
                    <ListItem
                      button
                      key="question-selection"
                      component={renderLink}
                      to={questionsOK ? '/overview' : '/question-selection'}
                      onClick={() => handleDrawerOpen()}
                    >
                      <ListItemIcon>
                        {!daysRemaining <= 0 ? (
                          <AssignmentTurnedIn />
                        ) : (
                          <Badge
                            color="secondary"
                            variant="dot"
                            invisible={questionsOK}
                          >
                            <AssignmentLate />
                          </Badge>
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          questionsOK
                            ? 'Questions Overview'
                            : 'Question Selection'
                        }
                        secondary={
                          daysRemaining <= 0
                            ? 'Open'
                            : `${daysRemaining} days remaining`
                        }
                        className={classes.drawerText}
                      />
                    </ListItem>
                  </Tooltip>
                  {Array.isArray(form) && questionsOK && (
                    <Tooltip title="Overview">
                      <ListItem
                        button
                        key="question-ready-overview"
                        component={renderLink}
                        to="/overview"
                        onClick={() => handleDrawerOpen()}
                      >
                        <ListItemIcon>
                          <ListAltIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="Overview"
                          className={classes.drawerText}
                        />
                      </ListItem>
                    </Tooltip>
                  )}
                </>
              )}
              {(admin || cernova) && (
                <>
                  <ListItem
                    button
                    key="scheduler"
                    onClick={() => setSchedulerOpen(true)}
                  >
                    <ListItemIcon>
                      <Schedule />
                    </ListItemIcon>
                    <ListItemText
                      primary="Scheduler"
                      className={classes.drawerText}
                    />
                  </ListItem>

                  <ListItem
                    button
                    key="settings"
                    onClick={() => handleDialogOpen()}
                  >
                    <ListItemIcon>
                      <Settings />
                    </ListItemIcon>
                    <ListItemText
                      primary="Settings"
                      className={classes.drawerText}
                    />
                  </ListItem>
                  <Tooltip title="Current active assessment">
                    <ListItem
                      button
                      key="question-ready-overview"
                      component={renderLink}
                      to="/assessment"
                      onClick={() => handleDrawerOpen()}
                    >
                      <ListItemIcon>
                        <Assessment />
                      </ListItemIcon>
                      <ListItemText
                        primary="Assessment"
                        className={classes.drawerText}
                      />
                    </ListItem>
                  </Tooltip>

                  <ListItem
                    button
                    key="Notifications editor"
                    component={renderLink}
                    to="/notifications"
                    onClick={() => handleDrawerOpen()}
                  >
                    <ListItemIcon>
                      <Chat />
                    </ListItemIcon>
                    <ListItemText
                      primary="Notifications"
                      className={classes.drawerText}
                    />
                  </ListItem>
                </>
              )}
              {cernova && (
                <>
                  <ListItem
                    button
                    key="testing"
                    component={renderLink}
                    to="/testing-board"
                    onClick={() => handleDrawerOpen()}
                  >
                    <ListItemIcon>
                      <DeveloperBoard />
                    </ListItemIcon>
                    <ListItemText
                      primary="TestingBoard"
                      className={classes.drawerText}
                    />
                  </ListItem>
                </>
              )}
            </List>
          </div>
        </SwipeableDrawer>
        <SettingsDialog
          open={dialogOpen}
          userDetails={userDetails}
          handleClose={handleDialogClose}
          handleCloseDrawer={handleDrawerOpen}
          changePrimaryColor={changePrimaryColor}
          changeSecondaryColor={changeSecondaryColor}
        />
        <Scheduler
          open={schedulerOpen}
          handleClose={() => setSchedulerOpen(!schedulerOpen)}
        />

        <Container>
          <div style={{ paddingTop: '70px' }}>{props.children}</div>
        </Container>
      </div>
      {/* <div className={classes.footer}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://cernova.com/">
            Cernova
          </Link>{' '}
          {new Date().getFullYear()}
        </Typography>
      </div> */}
    </div>
  );
}
