/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Timeline from '@mui/icons-material/Timeline';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import makeStyles from '@mui/styles/makeStyles';
import Checkbox from '@mui/material/Checkbox';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
// import CircularProgress from '@mui/material/CircularProgress'; // TODO: Add this for this cold start times
import useApi from '../functions/useApi';
import { useAuthContext } from '../context/AuthContext';
import { UsersContext } from '../context/UsersContext';
import { TagsContext } from '../context/TagsContext';
import { useCompanyContext } from '../context/CompanyContext';

const style = makeStyles(({ palette }) => ({
  tableHeader: {
    backgroundColor: palette.primary.light,
  },
  actionIcon: {
    marginRight: 25,
  },
  root: {
    marginBottom: '10rem',
  },
  paginator: {
    float: 'right',
  },
}));

function descendingComparator(a, b, orderBy) {
  let aValue = a[orderBy];
  let bValue = b[orderBy];

  // convert string to date if orderBy is last_test_date or last_seen
  if (orderBy === 'last_test_date' || orderBy === 'last_seen') {
    aValue = new Date(aValue);
    bValue = new Date(bValue);
  }
  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((element, index) => [element, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((element) => element[0]);
}

export default function UserAnalyticsTable(props) {
  const classes = style();
  const { users, hasResilience, hasSafety, tableFilter } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10000);
  const [open, setOpen] = React.useState(false);
  const [userForDeletion, setUserForDeletion] = React.useState([]);
  const { deleteAPI, put } = useApi();
  const authContext = useAuthContext();
  const { dispatchUsers } = useContext(UsersContext);
  const { identifiedData } = useCompanyContext();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('4');
  const { tags } = useContext(TagsContext);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const row = new URLSearchParams(location.search).get('rows');

    setRowsPerPage(parseInt(row, 10));

    const currentPage = new URLSearchParams(location.search).get('page');
    setPage(parseInt(currentPage, 10));

    const orderParameter = new URLSearchParams(location.search).get('order');
    if (orderParameter) setOrder(orderParameter);

    const orderByParameter = new URLSearchParams(location.search).get(
      'orderBy'
    );
    if (orderByParameter) setOrderBy(orderByParameter);
  }, [location]);

  const RoleExists = () => {
    const role = tags.filter((t) => t.question.toLowerCase() === 'role');

    if (role.length) {
      if (role[0].options && role[0].options.length) return true;
    }
    return false;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';

    const orderIndex =
      history.location.search.indexOf('&order') > 0
        ? history.location.search.indexOf('&order')
        : history.location.search.length;

    history.push({
      pathname: 'analytics',
      search: `${history.location.search.slice(0, orderIndex)}&order=${
        isAsc ? 'desc' : 'asc'
      }&orderBy=${property}`,
    });
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const columns = identifiedData
    ? [
        { id: 'name', label: 'Name' },
        tableFilter === 0 && {
          id: 'tests_completed',
          label: 'Tests Completed',
        },
        tableFilter === 0 &&
          hasResilience && { id: 'avg_resilience', label: 'Avg Resilience' },
        tableFilter === 0 &&
          hasResilience && { id: 'resilience', label: 'Resilience' },
        tableFilter === 0 && hasSafety && { id: 'safety', label: 'Safety' },
        tableFilter === 0 && { id: 'last_test_date', label: 'Last Test Date' },
        tableFilter === 0 && { id: 'analytics', label: 'Analytics' },
        tableFilter === 1 && { id: 'email', label: 'Email' },
        tableFilter === 1 && { id: 'phone_number', label: 'Phone Number' },
        tableFilter === 1 && RoleExists() && { id: 'role', label: 'Role' },
        tableFilter === 1 && { id: 'last_seen', label: 'Last Logged In' },
        tableFilter === 1 && { id: 'edit', label: 'Edit User' },
        tableFilter === 1 && { id: 'delete', label: 'Delete User' },
        tableFilter === 1 && { id: 'is_admin', label: 'Admin' },
      ].filter((c) => c)
    : [
        { id: 'name', label: 'Name' },
        tableFilter === 0 && { id: 'email', label: 'Email' },
        tableFilter === 0 && { id: 'phone_number', label: 'Phone Number' },
        tableFilter === 0 && RoleExists() && { id: 'role', label: 'Role' },

        tableFilter === 0 && { id: 'edit', label: 'Edit User' },
        tableFilter === 0 && { id: 'delete', label: 'Delete User' },
        tableFilter === 0 && { id: 'is_admin', label: 'Admin' },
      ].filter((c) => c);

  const manageUserLink = {
    pathname: '/create/user',
    tags: tags,
  };

  const handleChangePage = (event, newPage) => {
    history.push({
      pathname: 'analytics',
      search: `?rows=${rowsPerPage}&page=${newPage}${history.location.search.slice(
        history.location.search.indexOf('&filter')
      )}`,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    history.push({
      pathname: 'analytics',
      search: `?rows=${+event.target
        .value}&page=0${history.location.search.slice(
        history.location.search.indexOf('&filter')
      )}`,
    });
  };

  const DefaultCell = ({ value, align }) => (
    <TableCell {...(align && { align: align })} component="th" scope="row">
      {value}
    </TableCell>
  );

  const EditUserCell = ({ userId }) => (
    <TableCell component="th" scope="row">
      <Tooltip title="Update User">
        <IconButton
          edge="end"
          aria-label="View"
          color="primary"
          component={Link}
          to={`/user/analytics/${userId}/edit`}
          size="large"
        >
          <Edit />
        </IconButton>
      </Tooltip>
    </TableCell>
  );

  const handleClickOpen = (user) => {
    setOpen(true);
    setUserForDeletion(user);
  };
  const DeleteUserCell = ({ user }) => (
    <TableCell component="th" scope="row">
      <Tooltip title="Delete User">
        <IconButton
          disabled={authContext.userDetails.user_id === user.user_id}
          edge="end"
          aria-label="View"
          color="primary"
          onClick={() => handleClickOpen(user)}
          size="large"
        >
          <Delete />
        </IconButton>
      </Tooltip>
    </TableCell>
  );

  async function handleClose(user) {
    const res = await deleteAPI({ url: 'user', id: user.user_id });

    if (res.message.numberOfRecordsUpdated === 1) {
      dispatchUsers({
        type: 'REMOVE_USER',
        user_id: user.user_id,
      });
    }
    setOpen(false);
  }

  async function handleAdminChange(user) {
    // eslint-disable-next-line camelcase
    const editUser = (({ name, email }) => ({
      name,
      email,
    }))(user);
    editUser.is_admin = !user.is_admin;
    editUser.phoneNumber = user.phone_number;
    editUser.startDate = user.start_date;

    try {
      const result = await put({
        url: `user/${user.user_id}`,
        body: JSON.stringify(editUser),
      });
      console.log(result);
      if (result.message.numberOfRecordsUpdated === 1) {
        dispatchUsers({
          type: 'EDIT_USER',
          user: { ...user, is_admin: !user.is_admin },
        });
      }
    } catch (err) {
      console.log(err);
    }

    // console.log(editUser);
  }
  const AdminCell = ({ user }) => (
    <TableCell component="th" scope="row">
      <Checkbox
        disabled={authContext.userDetails.user_id === user.user_id}
        edge="start"
        checked={user.is_admin}
        tabIndex={-1}
        disableRipple
        onChange={() => handleAdminChange(user)}
      />
    </TableCell>
  );

  return (
    <>
      <Grid style={{ height: 600 }}>
        <TableContainer className={classes.root}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    className={classes.tableHeader}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                      {/* {orderBy === column.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null} */}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(users, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user, index) => (
                  <TableRow key={index}>
                    <DefaultCell value={user.name} />

                    {tableFilter === 0 && identifiedData && (
                      <>
                        <DefaultCell value={user.tests_completed} />

                        {hasResilience && (
                          <>
                            <DefaultCell value={user.avg_resilience} />
                            <DefaultCell value={user.resilience} />
                          </>
                        )}
                        {hasSafety && (
                          <>
                            <DefaultCell value={user.safety} />
                          </>
                        )}

                        <TableCell component="th" scope="row">
                          {user.last_test_date
                            ? user.last_test_date.split(' ')[0]
                            : null}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <Tooltip title="View User Analytics">
                            <IconButton
                              edge="end"
                              aria-label="View"
                              color="primary"
                              component={Link}
                              to={`/user/analytics/${user.user_id}/view`}
                              size="large"
                            >
                              <Timeline />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </>
                    )}

                    {tableFilter === 1 && identifiedData && (
                      <>
                        <DefaultCell value={user.email} />
                        <DefaultCell value={user.phone_number} />
                        {RoleExists() && (
                          <DefaultCell
                            value={
                              // TODO needs to be refactored quick fix to handle empty array
                              // eslint-disable-next-line no-nested-ternary
                              user.tags
                                ? user.tags.filter(
                                    (g) => g.tag_group.toLowerCase() === 'role'
                                  ).length > 0
                                  ? user.tags.filter(
                                      (g) =>
                                        g.tag_group.toLowerCase() === 'role'
                                    )[0].tag
                                  : ''
                                : ''
                            }
                          />
                        )}
                        <DefaultCell
                          align="center"
                          value={
                            user.last_seen ? (
                              `${user.last_seen.split(' ')[0]}`
                            ) : (
                              <NotInterestedIcon fontSize="small" dis />
                            )
                          }
                        />
                        <EditUserCell userId={user.user_id} />

                        <DeleteUserCell user={user} />
                        <AdminCell user={user} />
                      </>
                    )}

                    {!identifiedData && (
                      <>
                        <DefaultCell value={user.email} />
                        <DefaultCell value={user.phone_number} />
                        {RoleExists() && (
                          <DefaultCell
                            value={
                              // TODO needs to be refactored quick fix to handle empty array
                              // eslint-disable-next-line no-nested-ternary
                              user.tags
                                ? user.tags.filter(
                                    (g) => g.tag_group.toLowerCase() === 'role'
                                  ).length > 0
                                  ? user.tags.filter(
                                      (g) =>
                                        g.tag_group.toLowerCase() === 'role'
                                    )[0].tag
                                  : ''
                                : ''
                            }
                          />
                        )}
                        <EditUserCell userId={user.user_id} />
                        <DeleteUserCell user={user} />
                        <AdminCell user={user} />
                      </>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 1000, 10000]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.paginator}
          />
        </TableContainer>
        <Dialog
          open={open}
          onClose={() => handleClose('noDelete')}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you wish to delete {userForDeletion.name}?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please confirm that you wish to delete {userForDeletion.name}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Disagree
            </Button>
            <Button
              onClick={() => handleClose(userForDeletion)}
              color="primary"
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}
