import * as XLSX from 'xlsx';
import format from 'date-fns/format';
import { answers } from '../constants/assessmentPointers';

// export const csvDownload = (data, fileName, keepExtras) => {
export const csvDownload = ({
  data,
  fileName = `${new Date().toJSON().slice(0, 10).replace(/-/g, '/')}-file`,
  keepExtras = false,
  resilience = false,
  safety = false,
}) => {
  if (data) {
    const parsedData = data.map((i) => ({
      ...i,
      ...{ tags: i.tags },
    }));

    // eslint-disable-next-line array-callback-return
    parsedData.map((p, index) => {
      parsedData[index].Name = parsedData[index].name;
      parsedData[index].Email = parsedData[index].email;
      parsedData[index]['Phone Number'] = parsedData[index].phone_number;
      parsedData[index]['Start Date'] = parsedData[index].start_date;

      delete parsedData[index].name;
      delete parsedData[index].email;
      delete parsedData[index].phone_number;
      delete parsedData[index].start_date;

      if (Array.isArray(p.tags)) {
        // eslint-disable-next-line no-return-assign
        p.tags.map((t) => (parsedData[index][t.tag_group] = t.tag));
      }

      if (Array.isArray(p.results)) {
        p.results.map(
          // eslint-disable-next-line no-return-assign
          (t) => (parsedData[index][t.question] = answers[t.answer])
        );
      }

      const temp = { ...parsedData[index] };

      if (!Array.isArray(p.results)) {
        delete parsedData[index].resilience;
        delete parsedData[index].safety;
      }

      delete parsedData[index].avg_resilience;
      delete parsedData[index].avg_safety;
      delete parsedData[index].last_test_date;
      delete parsedData[index].tests_completed;
      delete parsedData[index].last_seen;

      if (keepExtras) {
        const dateFormat = (date) =>
          date ? format(new Date(date), 'dd/MM/yy') : '';

        if (safety) {
          parsedData[index].safety = temp.safety;
          parsedData[index].avg_safety = temp.avg_safety;
        }
        if (resilience) {
          parsedData[index].resilience = temp.resilience;
          parsedData[index].avg_resilience = temp.avg_resilience;
        }
        parsedData[index].last_test_date = dateFormat(temp.last_test_date);
        parsedData[index].tests_completed = temp.tests_completed;
        parsedData[index].last_seen = dateFormat(temp.last_seen);
      }

      delete parsedData[index].is_admin;
      delete parsedData[index].user_id;
      delete parsedData[index].company_id;
      delete parsedData[index].tags;
    });

    // eslint-disable-next-line array-callback-return
    parsedData.map((p, index) => {
      parsedData[index].user_disabled = '';
    });

    const wb = XLSX.utils.book_new();
    const getLongestObject = (arr) =>
      arr.reduce(
        (savedObj, obj) =>
          Object.keys(obj).length > Object.keys(savedObj).length
            ? obj
            : savedObj,
        ''
      );

    const ws = XLSX.utils.json_to_sheet(parsedData, {
      header: Object.keys(getLongestObject(parsedData)),
    });

    const sheetName = new Date().toISOString().split('T')[0];

    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${fileName || 'users'}.csv`);
  } else console.error('No data');
};

export default csvDownload;
